import React from 'react';
import config from '../../config';

export default function SocialLinks() {
  return (
    <section id="contact" className="contact-section bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Sídlo společnosti</h4>
                <hr className="my-4" />
                <div className="small text-black-50">Ency Consulting s.r.o.<br />
                                                    Jičínská 226/17, Žižkov, 130 00 Praha 3<br />
                                                    IČO: 05702283<br />
                                                    DIČ: CZ05702283<br />
                                                    <br />
                                                    Společnost je zapsána v obchodním rejstříku vedeném Městským soudem v Praze, oddíl
                                                    oddíl C, vložka 269185

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-envelope text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Email</h4>
                <hr className="my-4" />
                <div className="small text-black-50">
                  Zabezpečovací systémy:<br /><a href="mailto:ezs@ency.cz">ezs<span class="displaynone">null</span>@<span class="displaynone">null</span>ency<span class="displaynone">null</span>.cz</a><br /><br />
                  Informační technologie:<br /><a href="mailto:josef@ency.cz">josef<span class="displaynone">null</span>@<span class="displaynone">null</span>ency<span class="displaynone">null</span>.cz</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-mobile-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">Telefonní kontakt</h4>
                <hr className="my-4" />
                <div className="small text-black-50">Zabezpečovací systémy:<br /><a href="tel:+420604176458">+420 604 176 458</a><br /><br />
                                                    Informační technologie:<br /><a href="tel:+420776048632">+420 776 048 632</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}
