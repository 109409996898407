import React from 'react';
import config from '../../config';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import camera from '../assets/images/camera.jpg';
import partner from '../assets/images/partner.png';
import entrance from '../assets/images/entrance.jpg';
import ezs1 from '../assets/images/ezs.jpg';
import ezs2 from '../assets/images/ezs2.jpg';

export default function SocialLinks() {
  return (
   <section id="ezs" className="projects-section bg-light text-center">


         <div className="container">


   <div className="row">
               <div className="col-lg-8 mx-auto">
                   <h2 className="text-black mb-4">Bezpečnostní a kamerové systémy</h2>
                   </div></div>

           <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
             <div className="col-lg-6 background-white">
               <img className="img-fluid" src={partner} alt="" />
             </div>
             <div className="col-lg-6">
               <div className="bg-black text-center h-100 project">
                 <div className="d-flex h-100">
                   <div className="project-text w-100 my-auto text-center text-lg-left">
                     <h4 className="text-white">Zabezpečovací systémy</h4>
                     <p className="mb-0 text-white-50">
                       Naše firma je certifikována výrobcem ke kvalifikované montáži systému a jsme nositeli koncesované živnosti, která nás opravňuje k montáži zabezpečovací techniky v souladu s platnou legislativou. Jsme certifikovaný montážní partner společností Jablotron Alarm a.s. a Paradox security.
                     </p>
                     <hr className="d-none d-lg-block mb-0 ml-0" />
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className="row justify-content-center no-gutters">
             <div className="col-lg-6 background-white">
               <img className="img-fluid" src={camera} alt="" />
             </div>
             <div className="col-lg-6 order-lg-first">
               <div className="bg-black text-center h-100 project">
                 <div className="d-flex h-100">
                   <div className="project-text w-100 my-auto text-center text-lg-right">
                     <h4 className="text-white">Kamerové systémy</h4>
                     <p className="mb-0 text-white-50">
                       Provádíme montáže kamerových systémů od renomovaných zahraničních výrobců, jako jsou firmy Avtech, Mazi, Canon, Hikvision, Dahua, Acti a dalších.
                     </p>
                     <hr className="d-none d-lg-block mb-0 mr-0" />
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
                     <div className="col-lg-6 background-white">
                       <img className="img-fluid" src={entrance} alt="" />
                     </div>
                     <div className="col-lg-6">
                       <div className="bg-black text-center h-100 project">
                         <div className="d-flex h-100">
                           <div className="project-text w-100 my-auto text-center text-lg-left">
                             <h4 className="text-white">Vstupní systémy</h4>
                             <p className="mb-0 text-white-50">
                               Provádíme montáže elektronických vstupních systémů do rodinných domů, kanceláří i průmyslových objektů.
                             </p>
                             <hr className="d-none d-lg-block mb-0 ml-0" />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>

           <div className="row justify-content-center no-gutters">
             <div className="col-lg-6 background-white">
               <img className="img-fluid" src={ezs1} alt="" />
             </div>
             <div className="col-lg-6 order-lg-first">
               <div className="bg-black text-center h-100 project">
                 <div className="d-flex h-100">
                   <div className="project-text w-100 my-auto text-center text-lg-right">
                     <h4 className="text-white">Garance kvality</h4>
                     <p className="mb-0 text-white-50">
                       Naše firma Vám garantuje záruční a pozáruční servis a případě potřeby také poradenství. Máme za sebou již celou řadu montáží systémů s velmi dobrými výsledky a spokojenými uživateli.<br />

                     </p>
                     <hr className="d-none d-lg-block mb-0 mr-0" />
                   </div>
                 </div>
               </div>
             </div>
           </div>

            <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
                             <div className="col-lg-6 background-white">
                               <img className="img-fluid" src={ezs2} alt="" />
                             </div>
                             <div className="col-lg-6">
                               <div className="bg-black text-center h-100 project">
                                 <div className="d-flex h-100">
                                   <div className="project-text w-100 my-auto text-center text-lg-left">
                                     <h4 className="text-white">Diskrétnost</h4>
                                     <p className="mb-0 text-white-50">
                                       Vaše soukromí a bezpečí je pro nás prioritou, proto zásadně nesdělujeme detaily o našich zákaznících.
                                     </p>
                                     <hr className="d-none d-lg-block mb-0 ml-0" />
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                           <div className="col-lg-12 background-white">
                           <div className="col-lg-8 mx-auto">
                                       <p className="mb-0 text-black-50">
                                       <br />
                                       V případě zájmu o montáž, popř. servis zabezpečovacích, kamerových nebo přístupových systémů nás neváhejte kontaktovat, telefonicky nebo emailem.<br />
                                       <br />
                                       Na základě dodaných podkladů Vám sestavíme bezplatně cenovou nabídku, popř. poradíme s výběrem jednotlivých komponentů daného systému.<br />
                                       <br />
                                       </p>
                                       <a href="#contact" className="btn btn-primary">
                                       Kontaktujte nás
                                       </a>
                                       <br />&nbsp;
                                       <br />
                                     </div>
                           </div>

         </div>



       </section>
  );
}
