import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
import EZS from '../components/EZS';

import ipad from '../assets/images/tablet.png';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/security.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              O nás
            </a>
          </Scroll>
        </div>
      </div>
    </header>


    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">O nás</h2>
            <p className="text-white-50">
             Jsme rodinná firma dlouhodobě působící na trhu montáží elektronických zabezpečovacích systémů, kamerových systémů a elektronických vstupních systémů. Od roku 2017 jako společnost s ručením omezeným.<br />
            <br />
             Systémy navrhujeme vždy podle konkrétních požadavků a potřeb zákazníka s přihlédnutím na konkrétní specifikaci daného zabezpečovaného objektu, <a class="link-white" href="#ezs">rámcovou představu můžete získat zde</a><br />
             <br />
             Dále se zabýváme i oblastí informačních technologií, <a class="link-white" href="#it">více informací se můžete dozvědět zde.</a>
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" />&nbsp;<br />&nbsp;<br />&nbsp;<br />
      </div>
    </section>

    <EZS />

<section id="it" class="bg-black text-center projects-section">
    <div className="container">

        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">Informační technologie</h2>
            <p className="text-white-50">
            Poskytujeme služby v oblasti informačních technologiích a to zejména testování softwaru, automatizace a kontinuálního developmentu (CD/CI).<br />
            <br />
            Na základě Vaší poptávky jsme schopni tyto a další (Development, Agilní řízení, Full-stack týmy) tyto služby také outsourcovat a zprostředkovat Vám ty nejlepší profesionály v oboru.
            </p>
          </div>
        </div>

      </div>
</section>

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
